import { ProductModel } from './product.model';
import { TaxPaymentModel } from './tax-payment.model';

export class OrderModel {
  orderId: string;
  hotelId: string;
  registerId: string;
  locationId: string;
  openedTableRoomId: string;
  tableName: string;
  menuId: string;
  typeOfService: string;
  serviceStaff: string;
  createdDateTime: string;
  createdBy: string;
  totalAmount: number;
  products: ProductModel[];
  orderStatus: string;
  serviceWaiter: string;
  fufillmentStatus: string;
  orderNumber: string;
  requestedBy: string;
  comboName: string;
  lineTotal: string;
  requestedDateTime: string;
  price: string;
  orderProductId: string;
  comboProductId: string;
  comboType: string;
  errorProducts: any;
  selectedProductQty: [string, unknown][];
  employeeName: string;
  timeExpires: string;
  timeSpent: string;
  totalTax: number;
  taxLevies: TaxPaymentModel[];
  hasTaxLevies: boolean;
  userType: string;
  loungeName: string;
}
