import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  getCurrentHotelId() {
    const currentHotel = JSON.parse(localStorage.getItem('current:hotel'));
    return currentHotel?.hotelId;
  }

  getCurrentHotelName() {
    const currentHotel = JSON.parse(localStorage.getItem('current:hotel'));
    return currentHotel?.hotelName;
  }

  getLoggedInUserId() {
    const employee = JSON.parse(localStorage.getItem('employee'));
    return employee?.employeeId;
  }

  formatTimeToString(time) {
    return `${time.hour}:${time.minute}:00`;
  }

  formatDateToString(date) {
    return `${date.year}-${date.month}-${date.day}`;
  }

  formatDateTimeToString(date, time) {
    return `${date.year}-${date.month}-${date.day} ${time.hour}-${time.minute}:00`;
  }

  formatDateToObject(date) {
    if (typeof date === 'object' && date !== null) {
      return date;
    }

    const dateArr = date?.split(/[-T.: ]/);
    return {
      year: +dateArr[0],
      month: +dateArr[1],
      day: +dateArr[2]
    };
  }
  extractDate(dateTime) {
    if (dateTime === null || dateTime === undefined) {
      return '-1';
    } else {
      const resDateTime = dateTime.split(/[-T.: ]/);
      return `${resDateTime[2]}/${resDateTime[1]}/${resDateTime[0]}`;
    }
  }

  formatTimeToObject(time) {
    const timeArr = time.split(':');
    return { hour: +timeArr[0], minute: +timeArr[1], second: 0 };
  }

  getCurrentDateObject() {
    const currentDateTime = moment().format('YYYY-MM-DD HH:mm:SS');
    const dateArr = currentDateTime.split(/[-: ]/);
    return {
      year: +dateArr[0],
      month: +dateArr[1],
      day: +dateArr[2]
    };
  }

  extractDateTime(dateTime) {
    if (dateTime === null || dateTime === undefined) {
      return '-1';
    } else {
      const resDateTime = dateTime.split(/[-T.: ]/);
      let hourIncrease;
      if (+resDateTime[3] === 24) {
        hourIncrease = 0;
      } else {
        hourIncrease = +resDateTime[3];
      }
      resDateTime[3] = hourIncrease.toString();

      if (hourIncrease < 12) {
        let hShow;
        if (hourIncrease < 10) {
          hShow = '0' + resDateTime[3];
        } else {
          hShow = resDateTime[3];
        }
        return `${resDateTime[2]}/${resDateTime[1]}/${resDateTime[0]} ${hShow}:${resDateTime[4]} AM`;
      } else {
        let adjustedTime;
        if (hourIncrease === 12) {
          adjustedTime = 12;
        } else {
          adjustedTime = hourIncrease % 12;
        }
        if (adjustedTime < 10) {
          adjustedTime = '0' + adjustedTime;
        }
        return `${resDateTime[2]}/${resDateTime[1]}/${resDateTime[0]} ${adjustedTime}:${resDateTime[4]} PM`;
      }
    }
  }

  formatDateWithStroke(dateTime) {
    const dateArr = dateTime.split(/[-T.:+ ]/);
    return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
  }

  formatAMPM(time) {
    if (!time) {
      return null;
    }
    const timeArr = time.split(':');
    let hours = +timeArr[0];
    let minutes: string | number = +timeArr[1];
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  }

  generateRandomNumber() {
    return Math.floor(Math.random() * 100000000) + 1;
  }

  interpretError(err) {
    let message;
    let code;
    if (err instanceof HttpErrorResponse) {
      code = err.status;
      if (err.status === 0) {
        message =
          'An error occurred while connecting to server. Please contact tech support.';
      } else {
        message = err.error.message;
      }
    }
    return { code, message };
  }

  generateReferenceNumber() {
    return moment().format('YYMMDDHHmmSS');
  }

  generateInvoiceNumber() {
    return moment().format('MMSSYYDDHHmm');
  }

  getCurrentDateTime() {
    return moment().format('DD/MM/YYYY HH:mm A');
  }

  getHotelName(): string {
    const currentHotel = JSON.parse(localStorage.getItem('current:hotel'));
    return currentHotel?.hotelName;
  }

  getFBAccessRoleName(): string {
    const currentHotelRole = JSON.parse(localStorage.getItem('current:hotel'));
    return currentHotelRole?.fbAccessRole.roleName.trim();
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  convertWordToCamelCase(name) {
    const nameArr = name.split('_');
    return (
      nameArr[0] +
      nameArr
        .splice(1, nameArr.length)
        .map((word) => this.capitalizeFirstLetter(word))
        .join('')
    );
  }

  timeDifference(start, end) {
    if (!start || !end) {
      return 0;
    }
    // @ts-ignore
    return new Date(end) - new Date(start);
  }

  convertMillisecondsToTime (ms) {
    const hours = 60 * 60 * 1000;
    const minutes = 60 * 1000;
    const secs = 1000;
    const newHours = Math.floor(ms / hours);
    const rem = ms - (newHours * hours);
    const newMinutes = Math.floor( rem / minutes);
    const newSeconds = Math.floor((rem - (newMinutes * minutes)) / secs);
    return  `${newHours}hours, ${newMinutes}minutes and ${newSeconds}seconds`;
  }
}
