export const DASHBOARD_NAV = {
  name: 'Dashboard',
  id: 'dashboard',
  url: '/dashboard',
  icon: 'icon-speedometer'
};

export const PRODUCTS_NAV = {
  name: 'Products',
  id: 'products',
  url: '/products',
  icon: 'icon-grid',
  children: []
};

export const PRODUCTS_NAV_CHILDREN = [
  {
    name: 'Add Product',
    id: 'add_products',
    url: '/products/add-product',
    icon: 'icon-plus'
  },
  {
    name: 'List Product',
    id: 'view_products',
    url: '/products/list-products',
    icon: 'icon-list'
  },
  {
    name: 'Bulk Product Update',
    id: 'edit_products',
    url: '/products/bulk-product-update',
    icon: 'icon-docs'
  },
  {
    name: 'Product Mapping',
    id: 'product_invoice',
    url: '/products/product-invoice',
    icon: 'icon-map'
  },
  {
    name: 'Categories',
    url: '/products/categories',
    id: 'view_category',
    icon: 'icon-layers'
  },
  {
    name: 'Units',
    id: 'view_unit',
    url: '/products/units',
    icon: 'icon-equalizer'
  },
  {
    name: 'Menu',
    id: 'view_menu',
    url: '/products/menu',
    icon: 'icon-doc'
  }
];

export const CASH_REGISTER_NAV = {
  name: 'Cash Register',
  id: 'cash_register',
  icon: 'icon-notebook',
  children: []
};

export const CASH_REGISTER_NAV_CHILDREN = [
  {
    name: 'Manage Cash Register',
    id: 'view_cash_register',
    url: '/cash-register/list',
    icon: 'icon-book-open'
  },
  {
    name: 'Cash Register Audit',
    id: 'view_cash_register_audit',
    url: '/cash-register/audit',
    icon: 'icon-calculator'
  }
];

export const FULFILMENT_NAV = {
  name: 'Fulfilments',
  id: 'fulfilments',
  icon: 'icon-notebook',
  children: []
};

export const FULFILMENT_NAV_CHILDREN = [
  {
    name: 'Fulfilment List',
    id: 'fulfilment_lists',
    url: '/fulfilments/fulfilment-list',
    icon: 'icon-book-open'
  }
];

export const SALES_NAV = {
  name: 'Sales',
  id: 'sales',
  url: '/sales',
  icon: 'icon-chart',
  children: []
};

export const SALES_NAV_CHILDREN = [
  {
    name: 'List Sales',
    id: 'view_sales',
    url: '/sales/list-sales',
    icon: 'icon-pie-chart'
  },
  {
    name: 'Add Sales',
    id: 'add_sales',
    url: '/sales/add-sales',
    icon: 'icon-plus'
  },
  {
    name: 'Used Stocks',
    id: 'used-stock',
    url: '/sales/used-stock',
    icon: 'icon-plus'
  },
  {
    name: 'Fulfillment Center',
    id: 'view_sales',
    url: '/sales/orders',
    icon: 'icon-notebook'
  }

];

export const PURCHASES_NAV = {
  name: 'Purchases',
  id: 'purchases',
  icon: 'icon-wallet',
  children: []
};

export const PURCHASES_NAV_CHILDREN = [
  {
    name: 'Add Purchase',
    id: 'add_purchases',
    url: '/purchases/add-purchase',
    icon: 'icon-plus'
  },
  {
    name: 'View Purchases',
    id: 'view_purchases',
    url: '/purchases/view-purchases',
    icon: 'icon-book-open'
  }
];

export const STOCK_TRANSFER_NAV = {
  name: 'Stock Transfer',
  id: 'stock_transfer',
  url: '/stock-transfer',
  icon: 'icon-shuffle',
  children: []
};

export const STOCK_TRANSFER_NAV_CHILDREN = [
  {
    name: 'Add Stock Transfer',
    id: 'add_stock_transfer',
    url: '/stock-transfer/add-stock-transfer',
    icon: 'icon-plus'
  },
  {
    name: 'List Stock Transfer',
    id: 'view_stock_transfer',
    url: '/stock-transfer/list-stock-transfer',
    icon: 'icon-book-open'
  }
];

export const STOCK_ADJUSTMENT_NAV = {
  name: 'Stock Adjustment',
  id: 'stock_adjustment',
  url: '/stock-adjustment',
  icon: 'icon-note',
  children: []
};

export const STOCK_ADJUSTMENT_NAV_CHILDREN = [
  {
    name: 'Add Stock Adjustment',
    id: 'add_stock_adjustment',
    url: '/stock-adjustment/add-stock-adjustment',
    icon: 'icon-plus'
  },
  {
    name: 'List Stock Adjustment',
    id: 'view_stock_adjustment',
    url: '/stock-adjustment/list-stock-adjustment',
    icon: 'icon-book-open'
  }
];

export const STOCK_AUDIT_NAV = {
  name: 'Stock Audit',
  id: 'stock_audit',
  url: '/stock-audit',
  icon: 'icon-calculator',
  children: []
};

export const STOCK_AUDIT_NAV_CHILDREN = [
  {
    name: 'Add Stock Audit',
    id: 'add_stock_audit',
    url: '/stock-audit/add-stock-audit',
    icon: 'icon-plus'
  },
  {
    name: 'List Stock Audit',
    id: 'view_stock_audit',
    url: '/stock-audit/list-stock-audit',
    icon: 'icon-book-open'
  }
];
export const MFG_NAV = {
  name: 'MFG',
  id: 'mfg',
  url: '/mfg',
  icon: 'icon-cup',
  children: []
};

export const MFG_NAV_CHILDREN = [
  {
    name: 'Add MFG',
    id: 'add_mfg',
    url: '/mfg/add-mfg',
    icon: 'icon-plus'
  },
  {
    name: 'View MFG',
    id: 'view_mfg',
    url: '/mfg/view-mfg',
    icon: 'icon-book-open'
  }
];

export const REPORTS_NAV = {
  name: 'Reports',
  id: 'reports',
  url: '/reports',
  icon: 'icon-chart',
  children: []
};

export const REPORTS_NAV_CHILDREN = [
  {
    name: 'Products',
    id: 'products_report',
    url: '/reports/products',
    icon: 'icon-basket-loaded'
  },
  {
    name: 'Locations',
    id: 'locations_report',
    url: '/reports/locations',
    icon: 'icon-location-pin'
  },
  {
    name: 'Customer Sales',
    id: 'customer_sales_report',
    url: '/reports/customer-sales',
    icon: 'icon-graph'
  },
  {
    name: 'Direct Sales',
    id: 'direct_sales_report',
    url: '/reports/direct-sales',
    icon: 'icon-bag'
  },
  {
    name: 'Purchase History',
    id: 'purchase_report',
    url: '/reports/purchase-history',
    icon: 'icon-paypal'
  },
  {
    name: 'Management Reports',
    id: 'management_report',
    url: '/reports/management-report',
    icon: 'icon-bag'
  }
];

export const ADMIN_SECTION_BREAKER = {
  title: true,
  name: 'Admin'
};

export const SETTINGS_NAV = {
  name: 'Settings',
  id: 'settings',
  url: '/settings/business-location',
  icon: 'icon-settings',
  children: []
};

export const SETTINGS_NAV_CHILDREN = [
  {
    name: 'Business Locations',
    id: 'business_locations',
    url: '/settings/business-locations',
    icon: 'icon-location-pin'
  },
  {
    name: 'Business Customers',
    id: 'business_customers',
    url: '/settings/business-customers',
    icon: 'icon-bag'
  },
  {
    name: 'Roles',
    id: 'roles',
    url: '/settings/roles',
    icon: 'icon-organization'
  },
  {
    name: 'Suppliers',
    id: 'contacts',
    url: '/settings/suppliers',
    icon: 'icon-phone'
  },
  {
    name: 'Product File',
    id: 'product_files',
    url: '/settings/product-file',
    icon: 'icon-doc'
  }
];
