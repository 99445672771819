import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../shared/services/auth.service';
import { AppState } from '../../store/state/app.state';
import * as EmployeeActions from '../../store/actions/employee.action';
import * as moment from 'moment';
import { FunctionsService } from '../../shared/services/functions.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  loginDetail = {
    email: '',
    password: ''
  };
  loginError;
  showError = false;
  processing = false;
  showPage = false;
  loading = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>,
    private functions: FunctionsService
  ) {}

  ngOnInit() {
    if (this.authService.loggedIn()) {
      this.checkEmployeeAuth();
    } else {
      this.loading = false;
      this.showPage = true;
    }
  }

  checkEmployeeAuth() {
    this.authService.authTest('auth/test').subscribe(
      () => {
        this.router.navigate(['/dashboard']);
      },
      () => {
        this.loading = false;
        this.authService.logOut();
        this.showPage = true;
      }
    );
  }

  loginUser() {
    this.processing = true;
    this.authService.loginUser('auth/login', this.loginDetail).subscribe(
      (res) => {
        localStorage.setItem('x-access-token', res.token);
        this.processing = false;
        const payload = {
          token: res.token,
          ...res.data
        };

        this.store.dispatch(new EmployeeActions.loginEmployee(payload));
        this.router.navigate(['/hotels']);
        this.insertLoginDetails(res.data.employeeId);
      },
      (err) => {
        this.processing = false;
        if (err instanceof HttpErrorResponse) {
          this.showError = true;
          if (err.status === 0) {
            this.loginError = 'An error occurred while connecting to server';
          } else {
            this.loginError = err.error.message;
          }
        }
      }
    );
  }
  insertLoginDetails(employeeId) {
    const momentDateTime = moment().format('YYYY-MM-DD HH:mm:SS');

    const details = {
      employeeId,
      loginDateTime: momentDateTime
    };
    this.authService
      .insertLoginDetails('employees/insertLogin', details)
      .subscribe(
        () => {},
        (err) => {
          console.log(err);
        }
      );
  }

  onDismissError() {
    this.showError = false;
  }
}
