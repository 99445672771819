import { Component, Input, OnInit } from '@angular/core';
import { OrderTableRoomModel } from '../../../models/order-table-room.model';
import { OrderModel } from '../../../models/order.model';
import { FunctionsService } from '../../services/functions.service';
import { CashRegisterModel } from '../../../models/cash-register.model';
import { PurchaseModel } from '../../../models/purchase.model';
import { SalesModel } from '../../../models/sales.model';
import { StockRegisterModel } from '../../../models/stock-register.model';
import { StockAuditModel } from '../../../models/stock-audit.model';
import { MenuModel } from '../../../models/menu.model';
import { BusinessLocationModel } from '../../../models/business-location.model';

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.scss']
})
export class PrintPageComponent implements OnInit {
  @Input() printType:
    | 'invoice'
    | 'docket'
    | 'stockRegister'
    | 'purchase'
    | 'sales'
    | 'audit'
    | 'menu';
  @Input() title: string;
  @Input() invoiceData: OrderTableRoomModel = new OrderTableRoomModel();
  @Input() docketData: OrderModel = new OrderModel();
  @Input() stockRegisterData: CashRegisterModel = new CashRegisterModel();
  @Input() purchaseData: PurchaseModel = new PurchaseModel();
  @Input() salesData: SalesModel = new SalesModel();
  @Input() stockAuditData: StockAuditModel = new StockAuditModel();
  @Input() menuData: MenuModel = new MenuModel();
  // @Input() currentLocation: BusinessLocationModel = new BusinessLocationModel();
  currentDateTime = this.functions.getCurrentDateTime();
  hotelName: string;
  // tableRoomName: string;
  constructor(private functions: FunctionsService) {}

  ngOnInit(): void {
    this.hotelName = this.functions.getHotelName();
    // console.log('invoiceData', this.invoiceData);
    /*this.tableRoomName =
      this.invoiceData.tableName || this.docketData.tableName;
    console.log('this.tableRoomName', this.tableRoomName);*/
  }
}
